.forgot-password-overall {
  /* border: 2px solid black; */
  height: 100vh;
  width: 100vw;
  /* background-image: url("../images/forgot-pass-template.jpg"); */
  background-size: 100% 100%;
}

.forgot-password-percent {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 100%;
  width: 100%;
}


.forgot-password-title {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.forgot-pass-center {
  width: 400px;
  background-color: #fff;
  padding: 32px 24px;
  font-size: 14px;
  font-family: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif';
  color: #212121;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}


.forgot-password-content {
  /* border: 1px solid rgb(71, 71, 71); */
  background-color: white;
  padding: 4.125em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.75);
}

.forgot-password-main-box {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 60%;
  width: 100%;
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.forgot-password-mail {
  padding: 10px;
  border-radius: 10px;
  margin: 7px;
  border-radius: 10px;
}


.forgetpass-input {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

.confirmpass-input {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}


.forgoteye:enabled {
  position: absolute;
  right: 0%;
  left: 40%;
  border: transparent;
  background-color: transparent;
  /* border: 2px solid rgb(209, 168, 168); */

}


.forgot-password-cnt {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 20%;
  width: 40%;
}

.newpass-submit-cancel {
  /* border: 2px solid rgb(219, 195, 195); */
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sendBtn:enabled {
  height: 35px;
  width: 100px;
  transition: 0.2s ease-in-out;
  background-color: #020332;
  color: white;
  border-radius: 7px;
}

.sendBtn:hover {
  transform: scale(0.9);
  background-color: white;
  color: #020332;
}

.closebtnpass:enabled {
  /* border: 2px solid rgb(155, 150, 150); */
  height: 35px;
  width: 100px;
  transition: 0.2s ease-in-out;
  background-color: #ebdddd;
  color: rgb(0, 0, 0);
  border-radius: 7px;
}

.closebtnpass:hover {
  transform: scale(0.9);
  background-color: white;
  color: #020332;
}





@media only screen and (min-width : 0px) and (max-width : 576px) {
  .send-cancel {
    width: 80%;
  }

  .forgot-password-content {
    border: 1px solid rgb(71, 71, 71);
  }

  .forgot-password-mail {
    width: 100%;
  }

  .newpass-submit-cancel {
    width: 100%;
  }

  .forgoteye:enabled {
    top: 48%;
    right: 20%;
  }

}

@media only screen and (min-width : 576px) and (max-width : 768px) {
  .forgot-pass-center {
    margin-left: 20%;
  }
}

@media only screen and (min-width : 768px) and (max-width : 992px) {
  .forgot-pass-center {
    margin-left: 30%;
  }
}

@media only screen and (min-width : 992px) and (max-width : 1200px) {
  .forgot-pass-center {
    margin-left: 40%;
  }
}

@media only screen and (min-width : 1250px) and (max-width : 2500px) {
  .forgot-pass-center {
    margin-left: 35%;
  }
}