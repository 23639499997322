body {
  margin: 0px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 5px;
}

::-webkit-scrollbar-track:hover {
  background: white;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black;
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.stsearch-box input {
  border: transparent !important;
  padding: 10px 0px !important;
  text-indent: 15px !important;
}

.dropdown-checkbox:checked~.select-wrapper {
  z-index: 9999;
}

/* PhoneInput styles */
.react-tel-input input:focus {
  border: 1px solid gray !important;
  box-shadow: none;
}



.react-calendar__tile:disabled {
  cursor: not-allowed !important;
}

.gallery-images {
  column-count: 3 !important;
}

.gallery-images img {
  width: 100%;
  margin-bottom: 0.5em;
}



/* App.css or your relevant CSS file */
.react-calendar__tile.highlight-green {
  background-color: #38a1f7 !important;
  /* Green color */
  color: white !important;
  /* Make the text white for better contrast */
}

.react-calendar__tile.highlight-green:hover {
  background-color: #3078b3 !important;
  /* Slightly darker green on hover */
}

/* header */
#unique-dropdown {
  position: relative;
  display: inline-block;
}

#unique-dropdown span {
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.info-white {
  color: white !important;
}

#unique-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 30px;
  right: 10% !important;
  min-width: 170px;
  margin-top: 10px;
  border-radius: 4px;
  padding: 10px;
}

#unique-dropdown-content a {
  font-weight: 500;
  color: rgb(126, 126, 126);
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  padding: 10px 10px !important;
  transition: transform 0.2s ease;
}

#unique-dropdown-content a:hover {
  background-color: aliceblue;
  color: #000;
  transform: scale(0.95);
}

#unique-dropdown:hover #unique-dropdown-content {
  display: block;
}


.splide-landing .splide__list {
  height: 150px !important;
}

.splide__arrow {
  background-color: white !important;
  fill: black !important;
  border: 1px solid black;
}


.pagination-container {
  max-width: 300;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.overall-container {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/test.jpg"); */
}

.overall-admin-container {
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("https://img.freepik.com/free-vector/square-frame-background_53876-112294.jpg?t=st=1727263326~exp=1727266926~hmac=f41fa4d90632437cce4d0c5b42c64cc40dc93714337f405d82dc794c3a65e2a3&w=740"); */
  background-position: "100% 100%";
  background-size: cover;
}

.overall-container-profile {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overall-container-home {
  height: 100vh;
  width: 100vw;
  /* border: 2px solid #006379; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.overall-percent {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
}



.overall-percent-home {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}



.letters-color-testtitle {
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 26px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.term-title {
  font-size: x-large;
  padding-top: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.top13 {
  height: 13%;
  width: 100%;
}

.top20 {
  height: 15%;
  width: 100%;
}

.bottom-space {
  height: 100px;
  width: 100%;
}


.heading {
  height: 13%;
  width: 100%;
  top: 0px;
  z-index: 1000;
  background-color: #051036;
  color: white;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar.fixed-top {
  background-color: black !important;
}

.logo {
  height: 100%;
  width: 15%;
  padding-left: 9px;
  padding-bottom: 5px;
}

.logo-fit {
  object-fit: contain;
  cursor: pointer;
}

.logo-fit-header {
  padding-left: 9px;
  height: 35px;
  width: 157px;
  cursor: pointer;
  padding-top: 6px;
}

.logo-signin {
  height: 16%;
  width: 100%;
  /* border: 1px solid beige; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.logo-signin-img {
  height: 60%;
}

.centre-space {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 10%;
}

.signin-login {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 31%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.close-btn:hover {
  /* background-color: #d1a1a1; */
  color: black;
  transform: scale(0.9);
}

.signout-btn:enabled {
  height: 35px;
  width: 70%;
  font-size: medium;
  border-radius: 7px;
}

.signout-btn:hover {
  color: #0dcbfd;
  color: white;
  background-color: rgb(56, 56, 214);
}

.open-btn {
  background-color: transparent;
}

.reg-btn:hover {
  color: #0dcbfd;
}

.reg-btn {
  cursor: pointer;
  color: white;
  text-decoration: none;
  /* color: black; */
}

.text-overlay {
  font-size: 45px;
  padding: 7%;
}


.videoPlayer {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.db-box-cnt1 {
  /* border: 2px solid black;  */
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.db-box-cnt2 {
  /* border: 2px solid black;  */
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


/* centered slider */
.swiper {
  width: 100%;
  height: 100%;
  cursor: pointer;
}


.centered-text {
  position: absolute;
  background-color: #05103694;
  /* height: 17%; */
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  /* Initial scale set to 1 */
  color: white;
  font-size: 19px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border: 2px solid transparent;
  /* Transparent border initially */
  padding: 10px;
  font-size: large;
  /* Add some padding to the text */
  transition: transform 0.3s ease, border-color 0.3s ease;
  /* Add smooth transitions for zoom and border */
}



.container-one {
  /* border: 2px solid black; */
  height: 95%;
  width: 40%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-two {
  /* border: 2px solid black; */
  height: 100%;
  width: 40%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-1 {
  height: 30px;
}

.letters-color-howit {
  font-size: x-large;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}


.login-heading {
  /* border: 2px solid rgb(190, 242, 244); */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 20%;
}

.google-login {
  /* border: 2px solid cadetblue; */
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 4%;
}

.email-input {
  /* border: 2px solid red; */
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-input-popup {
  /* border: 2px solid red; */
  height: 45%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-hider {
  padding-left: 8%;
}

.input-font {
  height: 100%;
  width: 80%;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  /* background-color: #ebe8e8; */
  color: black;
  /* box-shadow: 14px 8px 4px black; */
}

.input-font::placeholder {
  color: black;
  font-size: 18px;
}

.input-font-pass::placeholder {
  color: black;
  font-size: 18px;
}

.password-heading {
  /* border: 2px solid cadetblue; */
  height: 7%;
  width: 100%;
  padding-left: 5%;
}

.password-input-style {
  /* border: 2px solid red; */
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-input-style-popup {
  /* border: 2px solid red; */
  height: 45%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-input {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-password {
  /* border: 2px solid mediumslateblue; */
  height: 15%;
  width: 100%;
  padding-left: 13%;
  padding-top: 18px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.forgot-password {
  /* border: 2px solid mediumslateblue; */
  height: 15%;
  width: 100%;
  padding-left: 10%;
  padding-top: 18px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.back-to-home {
  /* border: 2px solid mediumslateblue; */
  height: 15%;
  width: 100%;
  /* padding-left: 13%; */
  /* padding-top: 18px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/*  */
.sumbit-button {
  /* border: 2px solid red; */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.new-signup {
  /* border: 2px solid rgb(248, 248, 248); */
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.new-here {
  /* border: 2px solid rgb(238, 236, 232); */
  height: 100%;
  width: 74%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.letters-color-newhere {
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: medium;
}

.letters-color-showpassword {
  text-indent: 10px;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: 16px;
}

.letters-color-forgotpassword {
  text-indent: 10px;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: 16px;
  cursor: pointer;
}

.sign-up {
  /* border: 2px solid rgb(239, 237, 234); */
  height: 100%;
  width: 50%;
  display: flex;
  align-items: flex-end;
  padding-left: 3%;
}

.sign-in-btn:enabled {
  background-color: #172554;
  color: white;
  height: 28px;
}

.small-gap {
  /* border: 2px solid; */
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #5c5a5aba;
  align-items: end;
}

.email-heading {
  /* border: 2px solid cadetblue; */
  height: 7%;
  width: 100%;
  padding-left: 5%;
}

.email-pass-cnt {
  /* border: 2px solid yellow; */
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sumbit-font {
  height: 80%;
  width: 45%;
  border-radius: 22px;
}


.overall-percent-signup {
  height: 100%;
  width: 100%;
  /* border: 2px solid darkviolet; */
  box-sizing: border-box;
}

.signup-title {
  /* border: 2px solid rebeccapurple; */
  position: fixed;
  z-index: 1;
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 20px; */
  background-color: #040b26;
}

.signup-title-scrolled {
  position: fixed;
  z-index: 1;
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 20px; */
  background-color: #040b26c4;

  /* /* background-color: #030b4970; */
  backdrop-filter: blur(20px);
}

.prof-logo {
  /* border: 2px solid rebeccapurple;  */
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 35px;
}

.prof-title {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-content {
  height: 170%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-title-heading {
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}



.signup-box {
  border: 1px solid rgb(205 205 205 / 53%);
  height: 80%;
  width: 100%;
  border-radius: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 2px 2px 3px white;
}

.profile-buy-link {
  text-align: center;
}

.title-profile {
  /* border: 2px solid blueviolet; */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-name {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-email {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-gender {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

}


.title-relation {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-calender {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-month {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-nakshathra {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-nakshatra-tithi {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-nakshathra {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-Paksham {
  /* border: 2px solid firebrick; title-Tithiname*/
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-Tithiname {
  /* border: 2px solid firebrick; */
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.title-signup-btn {
  /* border: 2px solid firebrick; */
  height: 8%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 32px;
  margin: 13px;
}

.key-title {
  /* border: 2px solid grey; */
  height: 100%;
  width: 30%;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: flex-start;
  padding-left: 18px;
  align-items: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;

}

.value-input {
  /* border: 2px solid grey; */
  height: 100%;
  width: 70%;
}

.singup-input-box {
  border: 1px solid black;
  height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 7px;
  text-indent: 10px;
}

.placeholder-select {
  color: rgb(88, 88, 88) !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.stsearch-box {
  padding: 0px !important;
}


.singup-input-box::placeholder {
  color: black;
  font-size: 18px;
}

.signup-btn-style {
  height: 100%;
  width: 45%;
  border-radius: 7px;
  padding: 5px;
}


.janmadinam-lines {
  border: 2px solid darkolivegreen;
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-center {
  text-indent: 16%;
  font-size: medium;
}

.letters-color {
  color: rgb(0, 0, 0);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: medium;
}

.letters-color-header {
  /* color: white; */
  font-size: x-large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: white;
}

.letters-color-signin {
  text-align: center;
  /* color: white; */
  font-size: large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: rgb(0, 0, 0);
}

.letters-color-subhead {
  /* color: rgb(89 0 255); */
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: x-large;
  font-weight: bolder;
  text-decoration: double;
  /* text-decoration-line: underline; */
}

.letters-color-prf-head {
  padding: 10px;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: x-large;
  font-weight: bolder;
  /* text-decoration: double; */
  /* text-decoration-line: underline; */
}

.letters-color-countdown {
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: larger;
}

.letters-color-subhead-about {
  color: #913e3e;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: x-large;
  font-weight: bolder;
}

.letters-color-subhead1 {
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: large;
  padding-right: 25px;
}

.letters-color-subhead1-about {
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-size: large;
  padding-right: 25px;
}

.letters-color-test {
  color: cornsilk;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: xx-large;
}

.letters-color-acc {
  color: black;
  font-size: x-large;
  font-weight: bold;
}

.acc-ajst {
  padding: 20px;
  text-transform: capitalize;
}


.error-text {
  color: red;
  font-size: smaller;
}

.container-header {
  height: 13%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #051036;
}


.container-1 {
  /* border: 2px solid darkgray; */
  height: 50%;
  width: 100%;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  background-color: #051036;
}



.login-out {
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.my-profile {
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.wordpress {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.headers {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.footer-link {
  order: 1;
  height: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.links-title {
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: flex-end;
}

.links-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.footer-copyright {
  /* border: 2px solid palegoldenrod; */
  order: 2;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.link-icons-title {
  /* border: 2px solid palegoldenrod;  */
  /* order: 1; */
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: flex-end;
}

.link-icons {
  /* border: 2px solid palegoldenrod;  */
  /* order: 1; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.box-cnt-left {
  border-right: 1px solid #121031;
  /* height:100%; */
  width: 100%;
}

.box-cnt-right {
  /* border: 1px solid #121031;  */
  /* height:1000px; */
  width: 30%;
}

.related-searches {
  border: 1px solid black;
  height: 300px;
  width: 95%;
  padding-top: 30px;
  /* padding-left: 22px; */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 25px 21px 24px #7b7b7b;
}

.related-searches-title {
  /* border: 1px solid #ef0e12;  */
  height: 10%;
  width: 100%;
  text-align: center;
  /* color: black; */
}

.related-links {
  /* border: 1px solid #ef0e12;  */
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* color: black; */
}

.remove-underline {
  text-decoration-line: none;
  /* color: blue; */
}

.ads-space {
  border: 2px solid transparent;
  height: 300px;
  /* width: 100%; */
}

.pagesiderail-2 {
  border: 2px solid transparent;
  height: 700px;
  width: 100%;
}

.box-heading-font {
  font-size: xx-large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  font-weight: 700;
  padding-top: 30px;
  padding-left: 22px;
  text-align: center;
}

.login-sub-button:disabled {
  /* background-color: #35373a;
  color: #b9b7b7; */
  background-color: #cccccc;
  color: #666666;
  height: 36px;
  width: 77%;
  cursor: not-allowed;

}

.login-sub-button:enabled {
  /* background-color: rgb(0, 255, 247); */
  /* background-color: rgb(241 141 141); */
  background-color: #172554;
  color: white;
  height: 45px;
  width: 77%;
  /* border: 2px solid white; */
  border: 2px solid white;
  transition: 0.2s ease;
}

.login-sub-button:hover {
  transform: scale(0.9);
}

.signup-home {
  height: 30px;
}

.eye-btn:enabled {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbe1f3;
  color: white;
}


.admin-header {
  border: 1px solid rgba(71, 70, 70, 0.445);
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.admin-header1 {
  /* border: 2px solid black; */
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-header2 {
  /* border: 2px solid black; */
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.admin-body {
  border: 1px solid rgba(75, 74, 74, 0.596);
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;

}



/* AdminNavbar.css */
.adminpage-navbar {
  padding: 15px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
}

.btn:hover {
  color: #000000 !important;
  background-color: white !important;
  box-shadow: 5px 5px 2px rgba(204, 203, 203, 0.507);

}

.success {
  height: 10% !important;
  width: 80% !important;

}

.dropdown-menu-left {
  right: auto !important;
  left: 0 !important;
}





.sidebar {
  height: 100%;
  width: 20%;
  background-color: #06022e;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}



.sidebar-content {
  height: 100%;
  width: 80%;
  overflow: auto;
  margin-left: 10px;

}

.analysis-report {
  border: 1px solid #ddd;
  width: 50%;
  height: 50%;
  padding: 20px;
  margin: 20px;
  background-color: #f9f9f9;
}

.analysis-report h2 {
  color: #333;
}

.analysis-report p {
  font-size: 16px;
}

.admin-body2 {
  border: 1px solid rgba(75, 74, 74, 0.596);
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;

}

.body-chart1,
.body-chart2,
.body-chart3,
.body-chart4 {
  box-sizing: border-box;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.body-chart1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(211, 232, 250);
}

.chart1-child-1 {
  width: 100%;
  height: 50%;
  /* border: 2px solid black; */
  padding: 50px 100px;
  /* text-align: end; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.chart1-child-2 {
  width: 100%;
  height: 50%;
  /* border: 2px solid black; */
  padding: 10px 100px;
  /* text-align: center; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.body-chart2 {
  display: flex;
  justify-content: center;
  align-items: center;
}


.bg-body-tertiary {
  --bs-tertiary-bg-rgb: 0, 0, 55;
  --bs-navbar-color: white !important;
  --bs-navbar-hover-color: white !important;
}

.overflow {
  /* border: 2px solid burlywood; */
  /* height: 80%; */
  /* height: 80%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  max-height: 300px;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}


.home-container {
  /* border: 2px solid #db435e; */
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-image: url(../images/bp.png); */
  /* object-fit: cover; */
  /* background-image: url("../images/t8.jpg"); */
  background-size: 100% 100%;
  /* background-blend-mode: lighten; */
  /* overflow: auto; */
}

.ads {
  border: 1px solid #38383862;
  height: 1000px;
  width: 30%;
  box-sizing: border-box;
}

.home-cnt-body {
  /* border: 2px solid blue; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}


.table-add {
  /* border: 2px solid yellow; */
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #051036;
}

.table-add .letters-color {
  color: rgb(0, 0, 0);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.table-add-text {
  font-size: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white !important;
}

.home-banner-ads {
  border: 1px solid #38383862;
  height: 300px;
  width: 100%;
}

.table-outline {
  border: 2px solid red;
  border-collapse: collapse;
}

.table-heading {
  background-color: #f44336;
  color: white;
}

.link {
  font-size: 16px;
  color: white;
  text-decoration: none;
  font-weight: 200;
}


.link-login {
  font-size: 16px;
  color: white;
  text-decoration: none;
  font-weight: 200;
}

.blog {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  font-weight: 200;
}

/* Dropdown Button */
.dropbtn:enabled {
  background-color: #7d928a00;
  color: white;
  font-weight: 200;
  /* padding: 16px; */
  font-size: 18px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
/* .dropdown {
  position: relative;
  display: inline-block;
  background-color: #00020300;
} */

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #051036;
  min-width: 180px;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  height: 270px;
  /* overflow: auto; */
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 2px 16px;
  text-decoration: none;
  display: block;
  background-color: #051036;
  font-size: 14px;
}

.dropbtn {
  /* background-color: #7d928a00; */
  background-color: #19c28100;
  color: white;
  /* padding-top: 15px; */
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #051036;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  cursor: pointer;
  background-color: transparent;
}

.hightlight-link {
  color: rgb(207, 215, 230);
  font-weight: 500;
  text-shadow: 5px 10px 9px #e0fff3;
}

.modal-overall-container {
  height: auto;
}

.modal-header {
  height: 10% !important;
  /* background-color: #020332; */
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #00214f4d;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;

}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #02134bf5 !important;
  color: white !important;
}

.modal-body {
  /* width: 100px; */
  /* height: 100%; */
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;

}

.css-19kzrtu {
  padding: 0px !important;
}

.modal-footer {
  background-color: white !important;
}

.modal-title {
  color: white;
}

.modal-dialog {
  /* transform: none; */
  height: 317px;
  width: 276px;
  /* border: 2px solid pink; */
}

.modal.show .modal-dialog {
  transform: none;
  height: 87%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header .btn-close:enabled {
  background-color: #b2bcca !important;
}


.click-here:hover {
  color: #f65858;
}

/* Parallax.css */

.parallax-container {
  position: relative;
  overflow: hidden;
  height: 490px;
}

.parallax-background {
  position: absolute;
  top: 13px;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../images/login-img.jpg"); */
  /* background-size: contain; */
  background-size: 80% 100%;
  background-position: center;
  opacity: 1.8;
  transform: translateZ(-1px) scale(1.5);
  /* Add a slight zoom-in effect */
  transition: transform 0.2s ease-out;
}

.parallax-container:hover .parallax-background {
  transform: translateZ(-1px) scale(1.4);
  /* Zoom-in more on hover for a dynamic effect */
}

.parallax-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 70px;
  /* padding: 20px; */
  color: white;
  font-size: 24px;
  /* transform: translateY(50px); */
  /* Move the content down initially */
  transition: transform 0.5s ease-out;
  /* Add smooth transition for the content animation */
}

.parallax-container:hover .parallax-content {
  transform: translateY(0);
  /* Move the content up on hover to its original position */
}

/* Add parallax effect to the text */
.parallax-container:hover .parallax-content h1 {
  /* transform: translateX(-10px); */
  /* Slight horizontal movement */
}

.parallax-container:hover .parallax-content p {
  transform: translateX(10px);
  /* Slight horizontal movement */
}

.howandsigup {
  /* border: 2px solid green; */
  /* position: absolute; */
  /* top: 68%;
    left: 0px; */
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding-top: 64px; */
}

.howit {
  /* border: 2px solid rgb(52, 0, 194); */
  width: 90%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.sign {
  border: 2px solid rgb(204, 207, 12);
  width: 29%;
}

.howbtn:enabled {
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  color: rgb(247, 246, 246);
  background-color: #020444;
  width: 80%;
  height: 100%;
  border-radius: 6px;
  /* box-shadow: 10px 10px 15px #031146; */
  box-shadow: 10px 10px 7px #03114654;
  transition: transform 0.2s ease;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  /* animation: colorChange 3s forwards infinite; */
}

@keyframes colorChange {
  0% {
    background-color: rgb(228, 217, 217);
    transform: scale(0.8);
    color: black;
  }

  50% {
    background-color: rgb(255, 255, 255);
    transform: scale(1);
    color: black;
  }

  100% {
    background-color: rgb(6, 5, 43);
    transform: scale(0.8);
    color: white;
  }
}

.sign-parallax-btn:enabled {
  background-color: transparent;
  color: black;
  background-color: #8ea4fd;
  border-radius: 6px;
}

.reg-how:enabled {
  height: 20%;
  width: 20%;
  border-radius: 10px;
  /* background: linear-gradient(45deg, #ff9a9a, #caccff); */
  /* background: linear-gradient(to top, #829dff, #ffffff); */
  background-color: #172554;
  color: white;
  transition: transform 0.2s ease;
}

.reg-how:hover {
  /* background: linear-gradient(45deg, black, black); */
  background-color: white;
  color: #172554;
  transform: scale(0.9);
}

.hide {
  opacity: 0%;
}

.p-content {
  /* border: 2px solid black; */
  text-transform: capitalize;
  font-size: x-large;
  font-weight: 800;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: black;
  height: 35%;
  width: 100%;
  /* padding-top: 20px; */
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slogan {
  font-size: 20px;
  font-weight: 600;
  color: #172554;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  padding-right: 0%;
  padding-left: 20%;
  height: 20%;
  width: 100%;
  /* border: 2px solid green; */
}

.p-content-title {
  color: #172554;
  font-size: med27pxium;
}

.p-spans {
  /* border: 2px solid black; */
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end;
    align-items: flex-end; */
  /* padding-top: 20px; */
  padding-left: 60px;
}

.parallax-q1 {
  /* border: 2px solid black; */
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.pl-image {
  /* border: 2px solid black; */
  height: 100%;
  width: 30%;
  /* border-radius: 70px; */
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pl-quote {
  /* border: 2px solid black; */
  height: 100%;
  width: 70%;
}

.quote-line {
  /* border: 2px solid #db435e; */
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 20px;
}

.quote-author {
  /* border: 2px solid #db435e; */
  height: 20%;
  width: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.p-cnt-1 {
  border: 2px solid black;
  /* padding-top: 5%; */
  /* padding-right: 40%; */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: black;
  background-color: #c7c6c69c;
}

.p-cnt-2 {
  border: 2px solid black;
  /* padding-left: 27%; */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  color: black;
  background-color: #c7c6c69c;
}


.testimonial6 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #8d97ad;
  font-weight: 300;
  /* Add any other styling for the main container if needed */
}

.testimonial6 h1,
.testimonial6 h2,
.testimonial6 h3,
.testimonial6 h4,
.testimonial6 h5,
.testimonial6 h6 {
  color: #3e4555;
}

.testimonial6 .font-weight-medium {
  font-weight: 500;
}

.testimonial6 h5 {
  line-height: 22px;
  font-size: 18px;
}

.testimonial6 .subtitle {
  color: #8d97ad;
  line-height: 24px;
  font-size: 16px;
}

.testimonial6 .testi6 {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.testimonial6 .testi6 .nav-link {
  border-radius: 0px;
  margin: 8px -2px 8px 0;
}

.testimonial6 .testi6 .nav-link img {
  width: 70px;
  opacity: 0.5;
}

.testimonial6 .testi6 .nav-link.active {
  background: transparent;
  color: #8d97ad;
  border-right: 3px solid #2cdd9b;
}

.testimonial6 .testi6 .nav-link.active img {
  opacity: 1;
}

.testimonial6 .btn-danger {
  background: #ff4d7e !important;
  border: 1px solid #ff4d7e !important;
}

.testimonial6 .btn-md {
  padding: 18px 0px;
  width: 60px;
  height: 60px;
  font-size: 20px;
}

@media (max-width: 767px) {
  .testimonial6 .testi6 .nav-link {
    margin: 0px 0px -2px 0;
    padding: 10px;
  }

  .testimonial6 .testi6 {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    border-right: 0px solid rgba(120, 130, 140, 0.13);
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    margin-bottom: 40px;
  }

  .testimonial6 .testi6 .nav-link img {
    width: 40px;
  }

  .testimonial6 .testi6 .nav-link.active {
    border-right: 0px solid #2cdd9b;
    border-bottom: 3px solid #2cdd9b;
  }
}

.hidden {
  display: none;
}

.headroom {
  top: 70px;
  left: 0;
  right: 0;
  z-index: 1;
}

/*  #02162f */

.popup-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border:2px solid  #007bff; */
  /* width: 100%; */
  /* margin-top: 100px; */
}

.open-btn:enabled {
  padding: 10px 18px;
  background-color: #051036;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: transform 0.2s ease;
  /* width: 100%; */
}

.add-btn:enabled:hover {
  background-color: white;
  color: black;
}

.open-btn:hover {
  /* border: 2px solid black; */
  /* background-color:white ; */
  /* color: #e2e9eb; */

  color: #0dcbfd;

  /* transform: scale(0.9); */
}

.signin-header {
  text-decoration-line: none;
  color: white;
}

.signin-header:hover {
  color: rgb(51, 204, 255);
}

.popup {
  position: absolute;
  /* top: 210%; */
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(22, 21, 21, 0.644); */
  background-color: #161515e3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Adjust the z-index as needed */
  transition: transform 0.9s ease;
  /* Add smooth animation */
}

.popup-already {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: #161515e3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Adjust the z-index as needed */
  transition: transform 0.9s ease;
  /* Add smooth animation */
}

.popup-content {
  border-left: 2px solid black;
  height: 67%;
  width: 40%;
  /* background: linear-gradient(to top, #172554, white); */
  background-color: white;
  /* border: 2px solid white; */
  padding: 20px;
  border-radius: 36px;
  box-shadow: 34px 33px 18px #4d4d4e;
}

.popup-content-already {
  height: 90%;
  width: 100%;
  /* background-color: #025661; */
  /* background: linear-gradient(to bottom, #2d9bf5, black); */
  /* background: linear-gradient(to bottom,#015da8,black); */
  /* background: linear-gradient(to top, #172554, white); */
  background-color: white;
  border: 2px solid black;
  padding: 20px;
  border-radius: 45px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  /* background-color: #ff6347; */
  color: black;
  border: 2px solid black;
  cursor: pointer;
  border-radius: 8px;
  height: 38px;
  width: 80px;
  transition: 0.2s ease;
}

.error {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  background-color: blanchedalmond;
}

.error-align {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.about-align {
  padding-bottom: 4%;
  color: black;
}

.about-startup {
  /* border: 2px solid #27e4ba; */
  height: 34%;
  width: 100%;
  /* background-color: black; */
}

.about-title {
  /* border: 2px solid burlywood; */
  height: 27%;
  width: 100%;
  /* background-color: #5f9ea087; */
  /* background: linear-gradient(120deg, #1e337c, #fcfcfd); */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.about-lines {
  /* border: 2px solid burlywood; */
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-indent: 30px;
  padding-left: 20px;
  padding-right: 30px;
}

.letters-color-abttitle {
  color: aliceblue;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.letters-color-abtlines {
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  word-spacing: 3px;
  padding-top: 28px;
}

.getstart:enabled {
  border-radius: 8px;
  background-color: #529dc9a3;
  height: 6%;
}

.getstart:hover {
  /* background-color: blue; */
  color: white;
  background: linear-gradient(31deg, #eb8922b8, #da1467a6);
}

/* manual testimonial */


.testimonial-text {
  font-size: 16px;
  margin-bottom: 10px;
  padding-left: 83px;
}

.testimonial-author {
  font-size: 14px;
  font-style: italic;
  color: #666;
  padding-left: 214px;
}

.controls {
  /* border: 2px solid; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.prev-btn:enabled {
  border-radius: 10px;
  background-color: #172554;
  color: white;
}

.prev-btn:enabled:hover {
  background-color: white;
  color: #05182e;
}

.next-btn:enabled {
  background-color: #172554;
  color: white;
  border-radius: 10px;
  width: 10%;
}

.next-btn:enabled:hover {
  background-color: white;
  color: #05182e;
}

/* TestimonialSlider.css */
/* Add any global styling you might need */

.testimonial-slider-wrapper {
  width: 80%;
  /* Adjust as needed */
  margin: 0 auto;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  /* margin: 20px; */
  text-align: center;

  background-color: #afdbdf;
}

.testimonial-profile-pic {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

/* .testimonial-text {
    font-size: 16px;
    margin-bottom: 10px;
} */

/* .testimonial-author {
    font-size: 14px;
    font-style: italic;
    color: #666;
} */

/* Optional: Styling for the Carousel itself */

.carousel.carousel-slider {
  height: 100%;
}

.carousel .control-dots {
  margin: 0;
  /* background-color: rgb(0, 0, 36); */
}

.carousel.carousel-slider .control-arrow {
  top: 20px;
  color: #fff;
  font-size: 25px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  /* border: 2px solid black; */
  height: 85%;
}

/* .carousel .control-next.control-arrow:before {
    border-left: 8px solid #da0000;
} */

.testimonial-h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 50px; */
  height: 238px;
}

.carousel .slide .testimonial-profile-pic {
  width: 100px;
  /* Adjust the width as needed */
  height: 100px;
  /* Adjust the height as needed */
  margin-bottom: 15px;
  /* Adjust spacing */
  border-radius: 57px;
  /* Adjust border radius if needed */
}

.carousel-root {
  height: 305px;
  width: 95%;
}

.carousel.slide {
  height: 257px;
}

.carousel {
  box-sizing: border-box;
  /* height: 244px; */
}

.carousel.slide img {
  vertical-align: top;
  border: 0;
}

.carousel .thumbs {
  display: none;
  transition: all 0.15s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
  height: 52px;
}

.carousel img {
  width: 50%;
}

/* .carousel.control-dots {
    margin: 2px 0;
} */

.upload-btn:hover {
  background-color: rgb(7, 7, 201);
  color: white;
}

/* .pwd-container {
  position: absolute;
  top: 0%;
  left: 0;
  border: 2px solid white;
  background-color: #bebbbac7;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pwd-popup {
  height: 60%;
  width: 100%;
  background-color: #025661;
  border: 2px solid rgb(7, 7, 7);
  padding: 20px;
  border-radius: 45px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
} */

/* Add this CSS to your stylesheet */

.pwd-container {
  position: absolute;
  top: 0%;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #262627c7;
}

.pwd-popup {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.pwd-popup label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.pwd-popup input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pwd-popup button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pwd-popup button:hover {
  background-color: #45a049;
}


/* CELEBRATION */
.celebration-background {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  /* Adjust background color and opacity as needed */
  z-index: 9999;
  /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container {
  /* border: 2px solid greenyellow; */
  /* max-height: 300px; */
  /* max-height: 1000px; */
  /* height: 70%; */
  /* Set a maximum height for the container */
  overflow-y: 100%;
  /* Enable vertical scrollbar when the table exceeds the container height */
}

table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  width: 100%;
  /* table-layout: fixed; */
  /* Use fixed table layout to make header and body columns align */
  border-collapse: collapse;
}

.table-body {
  height: 100%;
  /* Adjust the height as needed */
  /* overflow-y: auto; */
  /* Enable vertical scrollbar when the table body exceeds the container height */
}

.table-header th {
  background-color: #f2f2f2;
  /* Add a background color to make the header stand out */
  position: sticky;
  /* top: 80px; */
  /* Stick to the top of the container */
  z-index: 1;
  /* Ensure header is displayed above the table body */
  height: 50px;
}

.table-header tr {
  height: 90px;
}

table {
  width: 100%;
  /* table-layout: fixed; */
  /* Use fixed table layout to align header and body columns */
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th,
td {
  padding: 8px;
  text-align: left;
}


.num {
  /* border: 2px solid #172554; */
  height: 40%;
  width: 100%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.reg-how:enabled {
  height: 98%;
  width: 50%;
  border-radius: 10px;
}

.pl-image-1 {
  /* border: 2px solid green; */
  border-radius: 38px;
  height: 60px;
  width: 100px;
  /* margin-right: -23px;
    margin-top: -57px; */
}

.howbtn:hover {
  background-color: rgb(255, 255, 255);
  color: #172554;
  box-shadow: 10px 20px 12px #031146;
  transform: scale(0.9);
}

/* MenuBar.css */

.menu-bar-container {
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #333;
  color: #fff;
  padding: 10px;
  transition: background-color 0.3s ease;
  /* Add a transition for smooth color change */
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #070000 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #070000 !important;
}

.carousel .control-dots .dot {
  background: rgb(5, 5, 109) !important;
}

.carousel.carousel-slider .control-arrow:hover {
  /* background: transparent !important; */
}

.home-signout {
  font-size: 20px;
  color: white;
  text-decoration: none;
}

/* Styles for the hamburger icon (Hamburger component) */
.Hamburger-react {
  width: 30px;
  height: 20px;
}

/* Styles when the menu is open */
.menu-bar-container.open {
  background-color: #172554;
  /* Set your desired background color when open */
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 400px;
  z-index: 999;
  /* display: grid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  color: white;
  padding-bottom: 0%;
}

/* Styles for the toggled state (active) */
.Hamburger-react.is-active div {
  background-color: #333;
  /* Change the color when the hamburger is active */
}

.menu-link {
  font-size: 20px;
  color: white;
  text-decoration: none;
  /* font-size: small; */
}

.edit-form {
  margin: 10px;
}

.edit-form .edit-form-title {
  margin: 10px;
  font-size: large !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  font-weight: 500 !important;
  text-align: center !important;
  padding: 20px !important;
}

.modal-overall-container .add-form-title {
  margin: 10px;
  font-size: large !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  font-weight: 500 !important;
  text-align: center !important;
  padding: 20px !important;
}

.form-group {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.edit-label {
  width: 30%;
  /* Adjust the width as needed */
  margin-right: 10px;
}

.edit-input {
  /* flex: 1; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 70%;

}

/* .edit-input .stsearch-box input {
  width: 100% !important;
  padding: 10px;
} */


.edit-keys {
  font-weight: 600;
}

/* CSS for the popup */
.popup-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* .edit-form {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
} */

.popup-content-edit {
  width: 50%;
  background: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* background-color: rebeccapurple; */
  overflow: auto;
  height: 100%;
}

.add-text {}

.svg-inline--fa {
  height: 20px;
  /* margin-right: 20px;
    margin-left: 20px; */
  cursor: pointer;
}

.edit-popup {
  display: flex;
  flex-direction: column;
}

.edit-title {
  border-bottom: 2px solid black;
  height: 20%;
  width: 100%;
  /* font-size: 25px; */
  font-weight: 600;
  /* display: flex; */
  /* padding-left: 15px; */
  /* justify-content: center;
    align-items: center; */
}

.edit-content {
  /* border: 2px solid black; */
  /* height: 50px;
    width: 370px;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center; */
  /* border: 2px solid black; */
  /* height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; */

}

.edit-buttons {
  border-top: 2px solid black;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  padding-left: 90px;
}

.edit-changes:enabled {
  background-color: rgb(8, 8, 80);
  color: white;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  height: 30px;
  font-weight: 500;
  width: 45%;

}

.edit-changes:disabled {
  background-color: rgb(101, 101, 110);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  height: 35px;
  font-weight: 500;
  width: 40%;
  cursor: not-allowed;

}

.edit-changes:hover:enabled {
  background-color: white;
  color: black;
  transform: scale(0.9);
  width: 45%;
}

.edit-close:enabled {
  /* background-color: rgb(8, 8, 80);
    color: white; */
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  height: 35px;
  font-weight: 500;
  width: 40%;
}

.edit-close:hover {
  /* background-color: white;
    color: black; */
  transform: scale(0.9);
}

.paksham-table {
  word-wrap: break-word;
  font-size: smaller;
  text-align: center;
}

.tithi-table {
  word-wrap: break-word;
  font-size: smaller;
  text-align: center;
}

.nakshatra-table {
  word-wrap: break-word;
  /* font-size: smaller; */
  text-align: center;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.month-table {
  word-wrap: break-word;
  /* overflow-wrap: break-word; */
  /* white-space: normal; */
  font-size: smaller;
  text-align: center;
}

.input-font-pass {
  height: 100%;
  width: 80%;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  /* background-color: #ebe8e8; */
  color: black;
  /* box-shadow: 14px 8px 4px black; */
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.input-font {
  padding-right: 30px;
}

.eye-btn:enabled {
  position: absolute;
  /* top: 50%;   */
  /* right: 5px;  */
  /* left: 70%; */
  left: 80%;
  /* transform: translateY(-50%); */
  border: none;
  background: none;
  cursor: pointer;
  /* padding-right: 50px; */
}

.eye-container-singupsignin {
  height: 100%;
  width: 10%;
}

.eye-btn-signin:enabled {
  position: absolute;
  /* top: 50%;   */
  /* right: 5px;  */
  /* left: 70%; */
  left: 75%;
  /* transform: translateY(-50%); */
  border: none;
  background: none;
  cursor: pointer;
  /* padding-right: 50px; */
}

.eye-container {
  height: 11%;
  width: 10%;
}

.forgot-mailpage-overall {
  /* border: 2px solid black; */
  height: 100vh;
  width: 100vw;
  /* background-image: url("../images/forgot-pass-template.jpg"); */
  background-size: 100% 100%;
}

.mailpage-percent {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 100%;
  width: 100%;
}

.mailpage-title {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mailpage-titlecard {
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mailpage-content {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 60%;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.mailpage-main-box {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-forget-mail {
  height: 90%;
  width: 100%;
  border-radius: 10px;
}

.input-forgetmail-cnt {
  /* border: 2px solid rgb(209, 168, 168); */
  height: 25%;
  width: 35%;
}

.send-cancel {
  /* border: 2px solid rgb(155, 150, 150); */
  height: 40%;
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.edit-icon {
  color: #292929db;
}

.sendBtn:enabled {
  /* border: 2px solid rgb(155, 150, 150); */
  height: 35px;
  width: 100px;
  transition: 0.2s ease-in-out;
  background-color: #0c1d8be3;
  color: white;
  border-radius: 7px;
}

.sendBtn:hover {
  transform: scale(0.9);
  background-color: white;
  color: rgba(9, 9, 116, 0.89);
}

.closebtnpass:enabled {
  /* border: 2px solid rgb(155, 150, 150); */
  height: 35px;
  width: 100px;
  transition: 0.2s ease-in-out;
  background-color: #0c1d8be3;
  color: white;
  border-radius: 7px;
}

.closebtnpass:hover {
  transform: scale(0.9);
  background-color: white;
  color: rgba(9, 9, 116, 0.89);
}

.back-home:enabled {
  border-radius: 5px;
  background-color: rgb(238, 224, 224);
}


/* PROFILE */

.pr-content {
  /* border: 2px solid #0a0708; */
  height: 90%;
  width: 60%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  /* justify-content: space-around; */
  flex-direction: column;
  border-radius: 5%;
  box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #00000005;
  background-image: url("../images/landing-phone-poster-01.gif");
  background-size: 150%;
  background-position: center;
  background-repeat: no-repeat;
  /* background: linear-gradient(to right, #f1f5ff,white); */
}



.pr-cus-name {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-id {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-gen {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-email {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-phone {
  /* border: 1px solid grey; */
  height: 15%;
  width: 100%;
}

.pr-cus-country {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.pr-cus-country .stdropdown-container {
  width: 300px !important;
  border: 1px solid rgb(112, 112, 112) !important;
}

/* .pr-cus-country .stsearch-box input {
  width: 100% !important;
} */

.pr-cus-state .stdropdown-container {
  width: 300px !important;
  border: 1px solid rgb(112, 112, 112) !important;
}

/* .pr-cus-state .stsearch-box input {
  width: 100% !important;
} */


.pr-cus-state {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.pr-profile img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  /* position: absolute; */
}

.camera-icon {
  position: absolute;
  top: 70%;
  border: 1px solid rgb(31, 30, 30);
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-header-profile {
  /* border: 2px solid #db435e; */
  position: fixed;
  top: 0;
  z-index: 100;
  height: 85px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #051036;
}

.profile-banner {
  /* border: 2px solid #000000; */
  height: 200px;
  width: 100%;
  /* background-image: url("../images/profile\ banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  opacity: 0.6; */
}

.profile-banner-pic {
  object-fit: contain;
  opacity: 0.8;
}

.profile-content {
  /* border: 2px solid #c243db; */
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: linear-gradient(to right, #f1f5ff, white);
}

.pr-sidebar {
  display: none;
  /* border: 2px solid #43d1db; */
  height: 100%;
  width: 35%;
}

.pr-sidebar-container {
  /* border: 2px solid #db4343; */
  height: 100%;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.pr-profile {
  position: absolute;
  cursor: pointer;
  top: 23%;
  left: 2%;
  border: 1px solid #8a8a8a;
  height: 130px;
  width: 130px;
  border-radius: 60%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sdid-disable {
  border: 1px solid #767676b5;
  color: #767676b5;
  border-radius: 7px;
}

.edit-email-button:disabled {
  height: 60%;
  width: 60%;
  border: 1px solid #767676b5;
  color: #767676b5;
  border-radius: 5px;
  cursor: not-allowed;

}

.profile-font {
  font-size: large;
  border-radius: 5px;
  border: 1px solid rgb(182, 182, 182);
  /* box-shadow: 8px 7px 2px grey; */
}



.pr-prof-title {
  /* border: 2px solid #db4343;  */
  height: 100%;
  width: 50%;
  font-size: x-large;
  font-weight: 700;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-indent: 25px;
}

.pr-header {
  /* border: 1px solid grey; */
  height: 10%;
  width: 100%;
  display: flex;
}

.pr-edit {
  /* border: 2px solid #db4343;  */
  height: 10%;
  width: 100%;
  /* margin-left: 2px;  */
  /*  padding-right: 2px; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 70%;
}

.edit-email-button:enabled {
  height: 60%;
  width: 60%;
  border: 1px solid black;
  /* padding-right: 69px; */
}

.edit-email-button:hover {
  background-color: white;
  color: black;
}

.edit-profile:enabled {
  height: 33px;
  width: 80px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #031146;
  color: white;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-profile:disabled {
  height: 33px;
  width: 80px;
  /* border: 2px solid black; */
  border-radius: 5px;
  background-color: #031146;
  color: white;
  cursor: not-allowed;

}

.save-profile:enabled {
  height: 35px;
  width: 80px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #031146;
  color: white;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-profile:enabled {
  height: 35px;
  width: 80px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: #031146;
  color: white;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.services-container {
  /* border: 2px solid black; */
  height: 900px;
  width: 100%;
}


.service-provider {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 10px; */
}

.service-provider th {
  background-color: #000000;
  color: white;
  height: 70px;
}

.service-provider th:first-child {
  text-align: left;
}

.service-provider th,
.service-provider td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.service-provider th h2 {
  margin: 0;
}

.service-provider tbody tr:nth-child(even) {
  /* background-color: #cdcff350; */
  font-size: 1.1em;
}

.service-provider tbody tr:nth-child(odd) {
  /* background-color: #cdcff350; */
  font-size: 1.1em;
}


.admin-sendmail {
  color: white;

}

.no-blue {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
}

/* Basic styling for the form container */
.adaccess-form-container {
  position: relative;
  height: 60%;
  width: 400px;
  margin: auto;
  padding: 40px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  /* Set the background image on the pseudo-element */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/admin.jpg');
    background-size: cover;
    filter: blur(1px);
    z-index: -1;
  }

  /* Opacity */
  opacity: 0.9;
}

/* Styling for labels */
.adaccess-form-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

/* Styling for input fields */
.adaccess-input-field {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

/* Styling for the submit button */
.adaccess-submit-button:enabled {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Hover effect for the submit button */
.adaccess-submit-button:hover {
  background-color: #45a049;
}

.video-container {
  border: 2px solid rgba(78, 77, 77, 0.075);
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.login-form {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* background-color: #ffffff; */
  padding: 30px;
  width: 400px;
  border-radius: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border-radius: 10px;
  background: #f8f8f8;
}

::placeholder {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.login-form button {
  align-self: flex-end;
}

.login-flex-column>label {
  color: #151717;
  font-weight: 600;
}

.login-inputForm {
  border: 1.5px solid #ecedec;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  /* padding-left: 10px; */
  transition: 0.2s ease-in-out;
}



.login-input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 85%;
  height: 100%;
}

.login-input:focus {
  outline: none;
}

.login-inputForm:focus-within {
  border: 1.5px solid #051036;
}

.login-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.login-flex-row>div>label {
  font-size: 14px;
  color: black;
  font-weight: 400;
}

.login-span {
  font-size: 14px;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none !important;
}

.login-p {
  text-align: center;
  color: black;
  font-size: 14px;
  margin: 5px 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.login-btn:enabled {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: rgb(6, 2, 46);
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.login-btn:hover {
  border: 1px solid #2d79f3;
  ;
}

/* register form */

.form {
  background-color: transparent;
  /* Set background color to transparent */
  padding: 4.125em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.75);
  position: relative;
}



.signup {
  color: rgb(77, 75, 75);
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 0.5em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.form--input {
  width: 280px;
  margin-bottom: 1.25em;
  height: 40px;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 1.0em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  outline: none;
}

.form--input-pass {
  width: 240px;
  margin-bottom: 1.25em;
  height: 40px;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 1.0em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  outline: none;
  border-right: none;
}

.form--input:focus {
  border: 1px solid #639;
  outline: none;
}

.form--marketing {
  display: flex;
  margin-bottom: 1.25em;
  align-items: center;
}

.form--marketing>input {
  margin-right: 0.625em;
}

.form--marketing>label {
  color: grey;
}

.checkbox,
input[type="checkbox"] {
  accent-color: #639;
}

.form--submit:enabled {
  width: 50%;
  padding: 0.625em;
  border-radius: 5px;
  color: white;
  background-color: #051036;
  border: 1px dashed #051036;
  cursor: pointer;
}

.form--submit:disabled {
  width: 50%;
  padding: 0.625em;
  border-radius: 5px;
  color: white;
  background-color: #343435;
  cursor: not-allowed;

}

.form--submit:enabled:hover {
  /* color: #051036;
  background-color: white; */
  border: 1px dashed #051036;
  cursor: pointer;
  transition: 0.5s;
}



.title {
  text-align: center;
  margin: 0;
}

.form-container {
  width: 400px;
  background-color: #fff;
  padding: 32px 24px;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #212121;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.form-container button:active {
  scale: 0.95;
}

.form-container .logo-container {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.form-container .form {
  display: flex;
  flex-direction: column;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-container .form-group input {
  width: 100%;
  padding: 12px 50px;
  border-radius: 6px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border: 1px solid #ccc;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: #031831;
}

.form-container .form-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #fff;
  background-color: #020332;
  border: none;
  width: 100%;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin: 12px 0;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.form-container .form-submit-btn:hover {
  background-color: #0d0241;
}

.form-container .link {
  color: #1778f2;
  text-decoration: none;
}

.form-container .signup-link {
  align-self: center;
  font-weight: 500;
}

.form-container .signup-link .link {
  font-weight: 400;
}

.form-container .link:hover {
  text-decoration: underline;
}

.dropdown-menu.show {
  color: black !important;
}



.reg-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  background-color: white;
  border-radius: 12px;
  /* padding: 20px; */
}

.reg-title {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.reg-message {
  color: #a3a3a3;
  font-size: 14px;
  margin-top: 4px;
  text-align: center;
}

.reg-inputs {
  margin-top: 10px;
}

.reg-inputs input {
  width: 32px;
  height: 32px;
  text-align: center;
  border: none;
  border-bottom: 1.5px solid #d2d2d2;
  margin: 0 10px;
}

.reg-inputs input:focus {
  border-bottom: 1.5px solid royalblue;
  outline: none;
}



.ui-wrapper {
  --width: 100%;
  --height: 50px;
  --background: #fff;
  --text-color: rgb(73, 73, 73);
  --border-color: rgb(185, 184, 184);
  --border-focus-color: #01021e;
  --shadow-color: rgba(34, 60, 80, 0.2);
  --shadow-focus-color: #01021e;
  --dropdown-button-color: #e6e6e6;
  --dropdown-button-hover-color: #dad9d9;
}

.ui-wrapper *,
.ui-wrapper *::before,
.ui-wrapper *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: sans-serif;
  color: var(--text-color);
}

.ui-wrapper {
  width: var(--width);
  height: var(--height);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 10px;
  position: relative;
  border: 1px solid var(--border-color);
  background-color: var(--background);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 10px;
  -webkit-box-shadow: 0px 2px 5px 0px var(--shadow-color);
  box-shadow: 0px 2px 5px 0px var(--shadow-color);
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui-wrapper>input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  left: 9999px;
}

.dropdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 9px 0 0 9px;
  background-color: var(--dropdown-button-color);
}

.dropdown-container::before {
  content: "🇦🇹";
  font-size: 20px;
  background: none !important;
}

.dropdown-container::after {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNDk0OTQ5IiB3aWR0aD0iNzAwcHQiIGhlaWdodD0iNzAwcHQiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDcwMCA3MDAiCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxwYXRoCiAgICAgICAgZD0ibTM4MC4zOSA0ODQuNzkgMzA3LjA0LTMwNS45OWMxNi43NjYtMTcuODEyIDE2Ljc2Ni00NS4wNTkgMC02MS44MjgtMTYuNzY2LTE2Ljc2Ni00NS4wNTktMTYuNzY2LTYxLjgyOCAwbC0yNzUuNiAyNzUuNi0yNzUuNi0yNzUuNmMtMTcuODEyLTE2Ljc2Ni00NS4wNTktMTYuNzY2LTYxLjgyOCAwLTE2Ljc2NiAxNi43NjYtMTYuNzY2IDQ0LjAxMiAwIDYxLjgyOGwzMDUuOTkgMzA1Ljk5YzE3LjgxMiAxNi43NjYgNDUuMDU5IDE2Ljc2NiA2MS44MjggMHoiCiAgICAgICAgZmlsbC1ydWxlPSJjdXJyZW50Q29sb3IiIC8+Cjwvc3ZnPg==");
  width: 12px;
  height: 12px;
  background-position: center;
  background-size: cover;
  margin-left: 5px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.select-wrapper {
  width: var(--width);
  position: absolute;
  top: calc(var(--height) + 20px);
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.select-wrapper ul {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 0;
  list-style: none;
  height: 300px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  white-space: nowrap;
}

.select-wrapper ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.select-wrapper ul li label {
  width: 100%;
}

.select-wrapper ul li,
.select-wrapper ul li * {
  cursor: pointer;
}

.select-wrapper ul li:hover {
  background: lightgray;
}

.select-wrapper ul li span {
  display: inline-block;
  margin-right: 15px;
}

.input-wrapper {
  width: 100%;
  padding-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  row-gap: 2px;
}

.input-wrapper legend {
  font-size: 11px;
}

.input-wrapper .textfield {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-wrapper .textfield::before {
  content: "+31";
  margin-right: 5px;
  white-space: nowrap;
}

.input-wrapper .textfield input {
  width: 100%;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
}

.invalid-msg {
  font-size: 12px;
  position: absolute;
  color: red;
  top: 115%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

/* actions */

#phonenumber:invalid+.invalid-msg {
  top: 110%;
  opacity: 1;
  visibility: visible;
}

.ui-wrapper:focus-within {
  border-color: var(--border-focus-color);
  -webkit-box-shadow: 0px 2px 5px 0px var(--shadow-focus-color);
  box-shadow: 0px 2px 5px 0px var(--shadow-focus-color);
}

.dropdown-container:hover {
  background-color: var(--dropdown-button-hover-color);
}

.dropdown-checkbox:checked~.select-wrapper {
  top: calc(var(--height) + 5px);
  opacity: 1;
  visibility: visible;
}

.dropdown-checkbox:checked+.dropdown-container::after {
  rotate: 180deg;
  translate: 0 -2px;
}

.ui-wrapper input#Austria:checked~.dropdown-container::before,
.ui-wrapper input#Austria:checked~.select-wrapper li.Austria {
  content: "🇦🇹";
  background-color: lightgray;
}

.ui-wrapper input#India:checked~.dropdown-container::before,
.ui-wrapper input#India:checked~.select-wrapper li.India {
  content: "🇮🇳";
  background-color: lightgray;
}

.ui-wrapper input#Belgium:checked~.dropdown-container::before,
.ui-wrapper input#Belgium:checked~.select-wrapper li.Belgium {
  content: "🇧🇪";
  background-color: lightgray;
}

.ui-wrapper input#Bulgaria:checked~.dropdown-container::before,
.ui-wrapper input#Bulgaria:checked~.select-wrapper li.Bulgaria {
  content: "🇧🇬";
  background-color: lightgray;
}

.ui-wrapper input#Croatia:checked~.dropdown-container::before,
.ui-wrapper input#Croatia:checked~.select-wrapper li.Croatia {
  content: "🇭🇷";
  background-color: lightgray;
}

.ui-wrapper input#Cyprus:checked~.dropdown-container::before,
.ui-wrapper input#Cyprus:checked~.select-wrapper li.Cyprus {
  content: "🇨🇾";
  background-color: lightgray;
}

.ui-wrapper input#Czech:checked~.dropdown-container::before,
.ui-wrapper input#Czech:checked~.select-wrapper li.Czech {
  content: "🇨🇿";
  background-color: lightgray;
}

.ui-wrapper input#Denmark:checked~.dropdown-container::before,
.ui-wrapper input#Denmark:checked~.select-wrapper li.Denmark {
  content: "🇩🇰";
  background-color: lightgray;
}

.ui-wrapper input#Estonia:checked~.dropdown-container::before,
.ui-wrapper input#Estonia:checked~.select-wrapper li.Estonia {
  content: "🇪🇪";
  background-color: lightgray;
}

.ui-wrapper input#Finland:checked~.dropdown-container::before,
.ui-wrapper input#Finland:checked~.select-wrapper li.Finland {
  content: "🇫🇮";
  background-color: lightgray;
}

.ui-wrapper input#France:checked~.dropdown-container::before,
.ui-wrapper input#France:checked~.select-wrapper li.France {
  content: "🇫🇷";
  background-color: lightgray;
}

.ui-wrapper input#Germany:checked~.dropdown-container::before,
.ui-wrapper input#Germany:checked~.select-wrapper li.Germany {
  content: "🇩🇪";
  background-color: lightgray;
}

.ui-wrapper input#Greece:checked~.dropdown-container::before,
.ui-wrapper input#Greece:checked~.select-wrapper li.Greece {
  content: "🇬🇷";
  background-color: lightgray;
}

.ui-wrapper input#Hungary:checked~.dropdown-container::before,
.ui-wrapper input#Hungary:checked~.select-wrapper li.Hungary {
  content: "🇭🇺";
  background-color: lightgray;
}

.ui-wrapper input#Iceland:checked~.dropdown-container::before,
.ui-wrapper input#Iceland:checked~.select-wrapper li.Iceland {
  content: "🇮🇸";
  background-color: lightgray;
}

.ui-wrapper input#Ireland:checked~.dropdown-container::before,
.ui-wrapper input#Ireland:checked~.select-wrapper li.Ireland {
  content: "🇮🇪";
  background-color: lightgray;
}

.ui-wrapper input#Italy:checked~.dropdown-container::before,
.ui-wrapper input#Italy:checked~.select-wrapper li.Italy {
  content: "🇮🇹";
  background-color: lightgray;
}

.ui-wrapper input#Latvia:checked~.dropdown-container::before,
.ui-wrapper input#Latvia:checked~.select-wrapper li.Latvia {
  content: "🇱🇻";
  background-color: lightgray;
}

.ui-wrapper input#Liechtenstein:checked~.dropdown-container::before,
.ui-wrapper input#Liechtenstein:checked~.select-wrapper li.Liechtenstein {
  content: "🇱🇮";
  background-color: lightgray;
}

.ui-wrapper input#Lithuania:checked~.dropdown-container::before,
.ui-wrapper input#Lithuania:checked~.select-wrapper li.Lithuania {
  content: "🇱🇹";
  background-color: lightgray;
}

.ui-wrapper input#Luxembourg:checked~.dropdown-container::before,
.ui-wrapper input#Luxembourg:checked~.select-wrapper li.Luxembourg {
  content: "🇱🇺";
  background-color: lightgray;
}

.ui-wrapper input#Malta:checked~.dropdown-container::before,
.ui-wrapper input#Malta:checked~.select-wrapper li.Malta {
  content: "🇲🇹";
  background-color: lightgray;
}

.ui-wrapper input#Netherlands:checked~.dropdown-container::before,
.ui-wrapper input#Netherlands:checked~.select-wrapper li.Netherlands {
  content: "🇳🇱";
  background-color: lightgray;
}

.ui-wrapper input#Norway:checked~.dropdown-container::before,
.ui-wrapper input#Norway:checked~.select-wrapper li.Norway {
  content: "🇳🇴";
  background-color: lightgray;
}

.ui-wrapper input#Poland:checked~.dropdown-container::before,
.ui-wrapper input#Poland:checked~.select-wrapper li.Poland {
  content: "🇵🇱";
  background-color: lightgray;
}

.ui-wrapper input#Portugal:checked~.dropdown-container::before,
.ui-wrapper input#Portugal:checked~.select-wrapper li.Portugal {
  content: "🇵🇹";
  background-color: lightgray;
}

.ui-wrapper input#Romania:checked~.dropdown-container::before,
.ui-wrapper input#Romania:checked~.select-wrapper li.Romania {
  content: "🇷🇴";
  background-color: lightgray;
}

.ui-wrapper input#Slovakia:checked~.dropdown-container::before,
.ui-wrapper input#Slovakia:checked~.select-wrapper li.Slovakia {
  content: "🇸🇰";
  background-color: lightgray;
}

.ui-wrapper input#Slovenia:checked~.dropdown-container::before,
.ui-wrapper input#Slovenia:checked~.select-wrapper li.Slovenia {
  content: "🇸🇮";
  background-color: lightgray;
}

.ui-wrapper input#Spain:checked~.dropdown-container::before,
.ui-wrapper input#Spain:checked~.select-wrapper li.Spain {
  content: "🇪🇸";
  background-color: lightgray;
}

.ui-wrapper input#Sweden:checked~.dropdown-container::before,
.ui-wrapper input#Sweden:checked~.select-wrapper li.Sweden {
  content: "🇸🇪";
  background-color: lightgray;
}

.ui-wrapper input#India:checked~.input-wrapper .textfield::before {
  content: "+91";
}

.ui-wrapper input#Austria:checked~.input-wrapper .textfield::before {
  content: "+43";
}

.ui-wrapper input#Belgium:checked~.input-wrapper .textfield::before {
  content: "+32";
}

.ui-wrapper input#Bulgaria:checked~.input-wrapper .textfield::before {
  content: "+359";
}

.ui-wrapper input#Croatia:checked~.input-wrapper .textfield::before {
  content: "+385";
}

.ui-wrapper input#Cyprus:checked~.input-wrapper .textfield::before {
  content: "+357";
}

.ui-wrapper input#Czech:checked~.input-wrapper .textfield::before {
  content: "+420";
}

.ui-wrapper input#Denmark:checked~.input-wrapper .textfield::before {
  content: "+45";
}

.ui-wrapper input#Estonia:checked~.input-wrapper .textfield::before {
  content: "+372";
}

.ui-wrapper input#Finland:checked~.input-wrapper .textfield::before {
  content: "+358";
}

.ui-wrapper input#France:checked~.input-wrapper .textfield::before {
  content: "+33";
}

.ui-wrapper input#Germany:checked~.input-wrapper .textfield::before {
  content: "+49";
}

.ui-wrapper input#Greece:checked~.input-wrapper .textfield::before {
  content: "+30";
}

.ui-wrapper input#Hungary:checked~.input-wrapper .textfield::before {
  content: "+36";
}

.ui-wrapper input#Iceland:checked~.input-wrapper .textfield::before {
  content: "+354";
}

.ui-wrapper input#Ireland:checked~.input-wrapper .textfield::before {
  content: "+353";
}

.ui-wrapper input#Italy:checked~.input-wrapper .textfield::before {
  content: "+39";
}

.ui-wrapper input#Latvia:checked~.input-wrapper .textfield::before {
  content: "+371";
}

.ui-wrapper input#Liechtenstein:checked~.input-wrapper .textfield::before {
  content: "+423";
}

.ui-wrapper input#Lithuania:checked~.input-wrapper .textfield::before {
  content: "+370";
}

.ui-wrapper input#Luxembourg:checked~.input-wrapper .textfield::before {
  content: "+352";
}

.ui-wrapper input#Malta:checked~.input-wrapper .textfield::before {
  content: "+356";
}

.ui-wrapper input#Netherlands:checked~.input-wrapper .textfield::before {
  content: "+31";
}

.ui-wrapper input#Norway:checked~.input-wrapper .textfield::before {
  content: "+47";
}

.ui-wrapper input#Poland:checked~.input-wrapper .textfield::before {
  content: "+48";
}

.ui-wrapper input#Portugal:checked~.input-wrapper .textfield::before {
  content: "+351";
}

.ui-wrapper input#Romania:checked~.input-wrapper .textfield::before {
  content: "+40";
}

.ui-wrapper input#Slovakia:checked~.input-wrapper .textfield::before {
  content: "+421";
}

.ui-wrapper input#Slovenia:checked~.input-wrapper .textfield::before {
  content: "+386";
}

.ui-wrapper input#Spain:checked~.input-wrapper .textfield::before {
  content: "+34";
}

.ui-wrapper input#Sweden:checked~.input-wrapper .textfield::before {
  content: "+46";
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }

  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }

  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }

  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}



.open-transition {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.open {
  max-height: 1000px;
  /* Adjust the maximum height as needed */
}

.filter-icon {
  padding: 10px;
  border-bottom: 1px solid grey;
}

.filter-icon:hover {
  background-color: #0038d51c;
}


.css-h93ljk-MuiTypography-root {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.css-r40f8v-MuiTypography-root {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

#resend-button {
  background-color: #020332d1;
}

#verify-button {
  background-color: #020332;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10.5px 0px !important;

}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  background-color: #f7f8fc;
}

.css-1agwao2-MuiFormControl-root {
  min-width: 65px !important;
}

.invite-icon:enabled {
  margin-right: 20px !important;
  padding: 10px;
  border: 1px solid #a4a4a49c;
  /* background-color: white; */
}

.invite-icon:enabled:hover {
  border: 1px solid black;
}



.notify-bell {
  color: white !important;
  cursor: pointer;
  margin-right: 10px;
}


.premium-notification {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  background-color: black;
  z-index: 1;
}



.premium-button {
  width: 180px;
  height: 40px;
  border: none;
  border-radius: 40px;
  background: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 0.8em;
  color: rgb(121, 103, 3);
  font-weight: 600;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition-duration: 3s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.144);
  background-size: 200% 200%;
}

.logoIcon path {
  fill: rgb(121, 103, 3);
}

.premium-button:hover {
  transform: scale(0.95);
  transition-duration: 3s;
  animation: gradient 5s ease infinite;
  background-position: right;
}

.scroll-to-top {
  right: 30px !important;
  border-radius: 10px !important;
  margin-bottom: 70px !important;
}

.nakshatra-option {
  height: 70%;
  width: 45%;
}

.date-option {
  height: 70%;
  width: 45%;
}

.nakshatra-option button:enabled,
.date-option button:enabled {
  height: 100%;
  width: 100%;
  border-radius: 15px;
}

.nakshatra-btn-active {
  background-color: white !important;
  color: black !important;
}

.nakshatra-btn-inactive {
  border: none !important;
  background-color: #b6b6b64f !important;
  color: grey !important;

}

.date-btn-active:enabled {
  background-color: white !important;
  color: white !important;
  transform: scale(1) !important;

}

.date-btn-inactive:enabled {
  background-color: #b6b6b64f !important;
  color: grey !important;
  border: grey;
}


.date-btn:enabled {
  background-color: #1d097c !important;
  color: white !important;
  position: relative;
  overflow: hidden;
  /* Add transition for smooth scaling */
}

.date-btn:enabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 10%;
  height: 100%;
  background: linear-gradient(to bottom right, transparent, rgba(192, 192, 192, 0.8), transparent);
  transform: skewX(-15deg);
  /* Skew the gradient to create a slant */
  animation: shine-animation 1.9s linear infinite;
  overflow: hidden !important;
  /* Adjust animation duration as needed */
}


@keyframes shine-animation {
  0% {
    left: 0%;
  }

  100% {
    left: 150%;
  }
}


.modal-content {
  height: 100%;
}


.dash-e-btn1:enabled {
  background-color: #cccccc45 !important;
  color: grey;
}

.dash-e-btn2:enabled {
  background-color: #1b3e69 !important;
  color: white;
  padding: 5px !important;
}

.pricing-container .tick-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-container .pricing-table tr {
  padding: 0px;
}

.sd-body-database-billing {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-billings-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.admin-billings-container {
  width: 100%;
  /* height: 100px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}





/* Add any other styles as needed */

@media print {
  .videoPlayer {
    display: none;
  }
}