body {
    margin: 0px;
    padding: 0px;
}

#datatablesSimple {
    height: 200px;
    overflow: hidden;
    background-color: #e6e6e6;
}

.sd-header-items:enabled {
    height: 40%;
    border-radius: 10px;
    width: 10%;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    /* Add transition for background-color, color, and border-color */
}

.sd-header-items:enabled:hover {
    height: 40%;
    /* border-radius: 7px; */
    width: 10%;
    color: rgb(0, 0, 0);
    /* border-bottom: 2px solid white; */
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    /* transition: backgroud-color 2s ease; */
}



.sd-adminpage {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.sd-header {
    height: 80px;
    background-color: rgb(12, 0, 54);
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.sd-body {
    height: 200%;
    /* margin-top: 30px; */
    padding: 20px;
    overflow-y: auto;
    flex: 1;
}

.sd-body-database {
    /* height: 100%;
    margin-top: 80px; */
    /* height: 250%; */
    overflow: none;
    display: flex;
    flex-direction: row;
    /* overflow: auto; */
}


.database-sidebar {
    border: 2px solid black;
    height: 100%;
    width: 20%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(12, 0, 54);
    z-index: 0;

}

.database-sidbarcontent {
    border: 2px solid black;
    height: 200%;
    width: 80%;
}



.sd-header-nav-list {
    list-style-type: none;
    display: flex;
    text-align: center;
    align-items: center;
    align-self: center;
    padding: 0px;
    margin: 0px;
}





.sd-header-nav-item {
    position: relative;
    cursor: pointer;
    padding: 3px;
    /* margin-top: 10px; */
}


.sd-header-nav-item:hover {
    color: black;
    background-color: #fff;
    border-radius: 10px;
    /* margin-top: 10px; */
}



.sd-header-nav-item:hover ol {
    margin-top: 0px;

}



.sd-header-dropdown {
    position: absolute;
    height: 100px;
    width: 200px;
    top: 100%;
    left: 0;
    padding-top: 15px;
    background-color: transparent;
    color: black;
    display: none;
    /* border: 1px solid rgb(68, 68, 68); */
}

.sd-header-dropdown div {
    background-color: white;
    color: rgb(0, 0, 0);
    /* height: 70%; */
    padding-left: 10px;
    text-align: start;
    list-style: none;
    padding-left: 20px;
    box-shadow: 1px 1px 5px rgb(192, 192, 192) !important;
    /* border-radius: 10px; */

}

.sd-header-dropdown-settings {
    height: 150px;
    position: absolute;
    width: 200px;
    top: 100%;
    padding-top: 15px;
    left: -100%;
    background-color: transparent;
    color: black;
    display: none;

    /* border: 1px solid rgb(68, 68, 68); */
}

.sd-header-dropdown-settings div {
    background-color: white;
    color: rgb(0, 0, 0);
    height: 70%;
    padding-left: 10px;
    box-shadow: 1px 1px 5px rgb(192, 192, 192) !important;
    /* border-radius: 10px; */
    /* border: 1px solid rgb(68, 68, 68); */
}



.sd-header-sub-dropdown {
    position: absolute;
    width: 250px;
    top: 35%;
    left: 100%;
    background-color: white;
    color: rgb(0, 0, 0);
    display: none;
    list-style: none;
    box-shadow: 1px 1px 5px rgb(192, 192, 192) !important;
    /* border: 1px solid rgb(68, 68, 68); */
}

.sd-header-dropdown-item {
    padding: 10px;
}

.sd-header-dropdown-item:hover {
    background-color: rgb(226, 226, 226);
    color: rgb(0, 0, 0);
}




.sd-header-sub-dropdown-item {
    padding: 10px;
}

.sd-header-sub-dropdown-item:hover {
    padding: 10px;
    background-color: rgb(226, 226, 226);
    color: rgb(0, 0, 0);
}

.sd-header-nav-item:hover .sd-header-dropdown {
    display: block;
}

.sd-header-nav-item:hover .sd-header-dropdown-settings {
    display: block;
}


.sd-header-dropdown-item:hover .sd-header-sub-dropdown {
    display: block;
}

.admin-userdate-finder {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.admin-userdate-finder thead,
th {
    background-color: #f2f2f2;
    position: sticky;
}

.admin-userdate-finder tr {
    border: 2px solid rgba(160, 160, 160, 0.13);
}



.body-datafinder {
    width: 100%;
    height: 200%;
    /* overflow: auto; */
    /* border: 2px solid black; */
}

.admin-dash-cards {
    width: 100%;
    height: 150px;
    border: 1px solid rgba(165, 165, 165, 0.137);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.admin-dash-cards .cards {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.admin-dash-cards .cards .red {
    background-color: #ef6161;

}

.admin-dash-cards .cards .blue {
    background-color: #00bc82;
}

.admin-dash-cards .cards .green {
    background-color: #01005c;

}

.admin-dash-cards .cards .pink {
    background-color: #b6791d;

}

.admin-dash-cards .cards .card {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;
    height: 100%;
    width: 40%;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: 400ms;
}

.admin-dash-cards .cards .card p.tip {
    font-size: 20px;
    font-weight: 700;
}

.admin-dash-cards .cards .card p.second-text {
    font-size: 23px;
}

/* .admin-dash-cards .cards .card:hover {
    transform: scale(1.1, 1.1);
} */

/* .admin-dash-cards .cards:hover>.card:not(:hover) {
    filter: blur(10px);
    transform: scale(0.9, 0.9);
} */

.sd-body-database-container {
    width: 100%;
    height: 100%;
    border: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.sd-body-database-container .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    margin-left: 20px !important;
}

.admin-customer-details {
    width: 100vw;
    height: 100vh;
    /* border: 2px solid black; */
}

.admin-custmomer-credentials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.admin-custmomer-credentials .admin-customer-table {
    width: 100%;
    height: 70%;
    text-align: center;
    border: 1px solid rgb(0, 0, 0);
}

.admin-custmomer-credentials .admin-customer-table td {
    text-align: center;
    border: 1px solid rgba(128, 128, 128, 0.103);
}

.admin-custmomer-credentials .admin-customer-tableper {
    width: 90%;
    height: 70%;
    text-align: center;
    border: 1px solid rgb(0, 0, 0);
}

.admin-custmomer-credentials .admin-customer-tableper td {
    text-align: center;
    border: 1px solid rgba(128, 128, 128, 0.103);
}


.admin-custmomer-credentials .admin-customer-tableprem {
    width: 100%;
    height: 70%;
    text-align: center;
    border: 1px solid rgb(0, 0, 0);
}

.admin-custmomer-credentials .admin-customer-tableprem td {
    height: 100px;
    font-size: 17px;
    text-align: center;
    border: 1px solid rgba(128, 128, 128, 0.103);
}


.lap-mega-menu {
    width: 100vw !important;
    height: 70vh !important;
    top: 30px !important;
    place-content: center !important;
}

.showall-menu:hover {
    background: white;
    color: black;
    padding: 2px;
    border-radius: 10px;
}

.lap-megamnu-container {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    gap: 10px;
    padding: 20px;

}

.lap-megamnu-container .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    width: 100% !important;
    border-bottom: 1px solid rgb(226, 226, 226);
    font-size: 15px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(63, 63, 63);

}

.lap-megamnu-container .szh-menu__submenu>.szh-menu__item {
    width: 100% !important;
    border-bottom: 1px solid rgb(226, 226, 226);
    color: rgb(63, 63, 63);
}





@media only screen and (min-width: 0px) and (max-width: 576px) {

    .box-admin-sent {
        width: 80% !important;
        margin-left: 15% !important;
        margin-right: 10px !important;
    }

    .admin-custmomer-credentials {
        flex-direction: column;
    }

    .admin-mob-title {
        background-color: aliceblue;
        font-size: medium;
        text-transform: uppercase;
        font-weight: 300;
        padding-left: 10%;
    }

    .admin-dash-cards {

        height: 500px;
    }

    .admin-dash-cards .cards {
        flex-direction: column;
    }

    .admin-dash-cards .cards .card {
        width: 100%;
        justify-content: center;
    }

    .sd-header {
        width: 100%;
        /* height: 15%; */
        /* Adjust this based on your layout */
        flex-direction: row;
        /* align-items: start; */
        justify-content: space-around;
        /* transform: translate(-100%); */
        overflow: hidden;
    }

    .sd-header.open {
        transition: transform 0.5s ease;
        transform: translate(0%);
    }

    .sd-header-items:enabled:hover {
        height: 5%;
        width: 40%;
    }

    .sd-header-items:enabled {
        height: 5%;
        width: 40%;
    }

    .sd-header-nav-list {
        /* list-style-type: none;
        display: flex;
        text-align: center; */
        align-items: start;
        align-self: start;
    }

}

@media only screen and (min-width: 576px) and (max-width: 768px) {



    .admin-mob-title {
        background-color: aliceblue;
        font-size: medium;
        text-transform: uppercase;
        font-weight: 300;
        padding-left: 10%;
    }

    .sd-header {
        width: 100%;
        height: 15%;
        /* Adjust this based on your layout */
        flex-direction: row;
        /* align-items: start; */
        justify-content: space-around;
        /* transform: translate(-100%); */
        overflow: hidden;
    }

    .sd-header.open {
        transition: transform 0.5s ease;
        transform: translate(0%);
    }

    .sd-header-items:enabled:hover {
        height: 5%;
        width: 40%;
    }

    .sd-header-items:enabled {
        height: 5%;
        width: 40%;
    }

    .sd-header-nav-list {
        /* list-style-type: none;
        display: flex;
        text-align: center; */
        align-items: start;
        align-self: start;
    }

}