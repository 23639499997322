:root {
    --white: white;
    --gray: #999;
    --lightgray: whitesmoke;
    --popular: #ffdd40;
    --starter: #07033d;
    --essential: #38a1f7;
    --professional: #FF7F45;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    background: none;
    border: none;
    cursor: pointer;
}

.pricing-table {
    border-collapse: collapse;
}

body {
    font: 18px/1.5 'Noto Sans', sans-serif;
    background: var(--lightgray);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}




.pricing-container {
    max-width: 850px;
    padding: 0 10px;
    margin: 0 auto;
}

.pricing-container-bullets {
    height: 200px;
    margin: 15px;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* align-items: center; */
}


/* TABLE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.pricing-table-wrapper {
    background: var(--white);
    overflow-y: auto;
}

.pricing-table {
    position: relative;
    z-index: 1;
    text-align: center;
    width: 100%;
}

.pricing-table thead {
    background: var(--white);
    transition: box-shadow 0.2s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.pricing-table-header th {
    position: sticky;
    top: 0px;
    z-index: 120;
}


.pricing-table tr {
    border: 1px solid whitesmoke;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.pricing-table thead {
    table-layout: fixed;
}

table th {
    position: sticky;
    top: 0px;
}

.pricing-table th,
.pricing-table td {
    width: 25%;
    min-width: 150px;
}

.pricing-table th:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
    line-height: 1.3;
    padding: 0 10px;
}

.pricing-table th:nth-child(1) .svg-wrapper {
    margin-top: 10px;
}

.pricing-table th:nth-child(1) svg {
    width: 22px;
    height: 22px;
}

.pricing-table th .heading {
    padding: 1rem;
    color: var(--white);
}

.pricing-table th:nth-child(2) .heading {
    background: var(--starter);
}

.pricing-table th:nth-child(3) .heading {
    background: var(--essential);
}

.pricing-table th:nth-child(4) .heading {
    background: var(--professional);
}

.pricing-table th .info {
    position: relative;
    padding: 1.5rem 0;
    border-left: 1px solid var(--lightgray);
}

.pricing-table th .popular {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 11px;
    background: var(--popular);
    padding: 4px 8px;
    border-radius: 2px;
}

.pricing-table th .free {
    position: absolute;
    top: 10px;
    /* right: 0; */
    font-size: 12px;
    background: var(--grey);
    padding: 4px 0px;
    border-radius: 2px;
}

.pricing-table th .amount {
    font-size: 2rem;
}

.pricing-table th .amount span {
    display: block;
    transform: translateY(-8px);
}

.pricing-table th:nth-child(2) .amount {
    color: var(--starter);
}

.pricing-table th:nth-child(3) .amount {
    color: var(--essential);
}

.pricing-table th:nth-child(4) .amount {
    color: var(--professional);
}

.pricing-table th .billing-msg,
.pricing-table th .amount span {
    font-weight: normal;
    font-size: 0.8rem;
}

.pricing-table th button {
    display: inline-block;
    border-radius: 20px;
    padding: 8px 20px;
    margin-top: 10px;
    transition: all 0.2s;
}

.pricing-table th:nth-child(2) button {
    color: var(--starter);
    border: 1px solid var(--starter);
}

.pricing-table th:nth-child(2) button:hover {
    background: var(--starter);
}

.pricing-table th:nth-child(3) button {
    color: var(--essential);
    border: 1px solid var(--essential);
}

.pricing-table th:nth-child(3) button:hover {
    background: var(--essential);
}

.pricing-table th:nth-child(4) button {
    color: var(--professional);
    border: 1px solid var(--professional);
}

.pricing-table th:nth-child(4) button:hover {
    background: var(--professional);
}

.pricing-table th button:hover {
    color: var(--white);
}

.pricing-table td {
    padding: 10px;
}

.pricing-table td:not(:first-child) {
    border-left: 1px solid var(--lightgray);
}

.pricing-table td:first-child {
    font-size: 1rem;
    text-align: left;
}

.pricing-table svg {
    width: 18px;
    height: 18px;
}

.pricing-table svg.not-included {
    fill: var(--gray);
}

.pricing-table svg.starter {
    fill: var(--starter);
}

.pricing-table svg.essential {
    fill: var(--essential);
}

.pricing-table svg.professional {
    fill: var(--professional);
}


/* premium checkout */

.premium-checkout-overall {
    width: 100%;
    height: 170%;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: row;
}

.premium-checkout-left {
    width: 40%;
    height: 100%;
    border-right: 1px solid rgba(104, 104, 104, 0.486);
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.pc-left-1 button:enabled {
    width: fit-content;
    display: flex;
    padding: 1.2em 1rem;
    cursor: pointer;
    gap: 0.4rem;
    font-weight: bold;
    border-radius: 10px;
    text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
    background: linear-gradient(15deg, #880088, #aa2068, #cc3f47, #de6f3d, #f09f33, #de6f3d, #cc3f47, #aa2068, #880088) no-repeat;
    background-size: 300%;
    color: #fff;
    border: none;
    background-position: left center;
    box-shadow: 0 30px 10px -20px rgba(0, 0, 0, .2);
    transition: background .3s ease;
}

.pc-left-1 button:enabled:hover {
    background-size: 320%;
    background-position: right center;
}

.pc-left-1 button:enabled:hover svg {
    fill: #fff;
}

.pc-left-1 button:enabled svg {
    width: 23px;
    fill: #f09f33;
    transition: .3s ease;
}


/* .pc-left-1 button:enabled {} */

.pc-left-1 {
    /* border: 2px solid black; */
    height: 10%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.pc-left-2 {
    /* border: 2px solid black; */
    height: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-around;
}

.pc-left-1 i,
.pc-left-1 b {
    font-size: 20px;
    padding-left: 5%;
}

.pc-left-1 b {
    font-size: xx-large;
}

.pc-left-2 p::before {
    content: "\2605";
    /* Unicode for solid star */
    display: inline-block;
    margin-right: 15px;
    /* Adjust as needed */
    font-size: large;
}


.pc-left-2 p {
    padding: 10px;
    font-size: large;
    /* font-weight: 100; */
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.premium-checkout-right {
    height: 110%;
    width: 60%;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow-y: auto;
}

.pc-right-container {
    height: 100%;
    width: 100%;
}

.pc-right-1 {
    /* border: 1px solid rgb(235, 235, 235); */
    height: 15%;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}


.pc-right-3 {
    /* border: 1px solid rgb(235, 235, 235); */
    height: 15%;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.pc-right-4 {
    /* border: 1px solid rgb(235, 235, 235); */
    /* height: 150px; */
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.payment-verify {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.payment-verify .resend-cancel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.pc-right-4 button:enabled {
    /* border-right: 2px solid white; */
    background-color: rgb(0, 1, 68);
    color: white;
    box-shadow: 6px 6px 6px #3c3e77c4;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transition: 0.2s ease;
}

.pc-right-4 button:hover {
    /* border-right: 2px solid white; */
    transform: scale(0.9);
    background-color: white;
    color: rgb(0, 1, 68);
    box-shadow: 10px 15px 10px #090b3bc4;
    border-left: 1px solid black;
    border-top: 1px solid black;
}



.pc-right-1 .stsearch-box input {
    height: 55px;
}

.pc-right-3 .stsearch-box input {
    height: 55px;
    width: 100% !important;
}

.pc-right-2 {
    /* border: 2px solid black; */
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* padding-left: 7%; */
    /* align-items: center; */
}

.pc-right-1 .pc-right-input {
    width: 250px;
    display: flex;
    flex-direction: column;
}

.pc-right-3 .pc-right-input {
    width: 250px;
    display: flex;
    flex-direction: column;
}

.pc-right-input b {
    padding: 10px;
}

.pc-right-2 b {
    padding-left: 15px;
}

.css-tccdxk {
    max-width: 250px !important;
    /* height: 200px !important; */

}

.pricing-star {
    font-size: 20px;
    margin-right: 20px;
    font-weight: 800;
    color: rgb(95, 138, 231);
}

@keyframes bgAnimation {
    0% {
        background-position: 0% 50%;
        /* Start position */
    }

    100% {
        background-position: 100% 50%;
        /* End position */
    }
}

/* Style for the premium background */
.premium-background {
    /* background: linear-gradient(to right, #eef8ed, #e6f7fa, #e9f7e3); */
    /* Gradient background */

    background-size: 200% 100%;
    /* Double the width for the gradient */
    animation: bgAnimation 5s infinite linear;
    /* Apply background color animation */
}

.premium-bg-none {
    background-color: white !important;
    /* padding-right: 10px; */
}

.premium-background .premium-bg-none li ::marker {
    background-color: white !important;
}

.premium-background li.premium-bg-none::before {
    background-color: white !important;
    /* Background color of the marker for the first li */
}

.premium-bottom-btn :enabled {
    background-color: var(--starter) !important;
}


.premium-table-btn:enabled {
    background-color: #108bff !important;
    color: white !important;
    position: relative;
    overflow: hidden;
    text-transform: capitalize !important;

    /* Add transition for smooth scaling */

}

.premium-table-btn:enabled::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    /* Start off-screen */
    width: 10%;
    /* Increase width to ensure the shine covers the button */
    height: 100%;
    background: linear-gradient(to bottom right, transparent, rgb(255, 255, 255), transparent);
    /* Silver shine gradient with a slant */
    transform: skewX(-15deg);
    /* Skew the gradient to create a slant */
    animation: shine-animation 1.9s linear infinite;
    overflow: hidden !important;
    /* Adjust animation duration as needed */
}



@media only screen and (min-width: 0px) and (max-width: 576px) {

    .pricing-table svg {
        width: 22px;
        height: 22px;
    }

    .pricing-table th,
    .pricing-table td {
        min-width: 0px;
        font-size: smaller;
    }

    .pricing-table td {
        /* height: 105px; */
        padding: 10px !important;
        text-align: center;
        place-content: center;
        font-size: medium;
    }

    .pricing-table tr {
        padding: 0px !important;
        text-align: center;
        place-content: center;
        font-size: medium;
    }

    .pricing-table .heading {
        justify-content: center;
    }

    .pricing-table th .amount {
        font-size: 1.5rem;
        margin-top: 10px;
    }

    .pricing-table thead tr {
        height: 170px !important;
    }

    .pricing-table-col1 {
        width: 160px !important;
    }

    .premium-background {
        background: linear-gradient(to right, #eef8ed, #e6f7fa, #e9f7e3);
        background-size: 200% 100%;
        /* background-color: #c4c4c436; */
        /* Light yellow */
        /* Dark gray */

        animation: bgAnimation 5s infinite linear;
        /* Apply background color animation */
    }



    .h6-features {
        order: 3 !important;
    }

    .pricing-free.order-1 {
        order: 1 !important;
        /* Order when URL contains "features" */
    }

    .pricing-premium.order-1 {
        order: 6 !important;
    }

    .pc-left-1 {
        height: 20%;
    }

    .pc-right-1 .pc-right-input {
        margin-bottom: 20px;
    }

    .pc-right-2 .pc-right-input {
        margin-bottom: 20px;
    }

    .pc-right-3 .pc-right-input {
        margin-bottom: 20px;
    }

    .pc-left-2 {
        display: none;
    }



    .pricing-container-bullets {
        height: 250px;
        margin: 15px;
    }

    .pricing-overall {
        padding: 2px !important;
    }



    .pricing-free-content li {
        /* display: flex; */
        align-items: center;
        font-size: 1em;
        font-weight: 500;
        line-height: 40px;
        /* border: 2px solid white; */

    }

    .pricing-free-content span {
        margin-left: 10px;

    }


    .pricing-free-content svg.starter {
        fill: var(--starter);
        width: 22px;
        height: 22px;
    }

    .pricing-free-content svg.essential {
        fill: var(--essential);
        width: 22px;
        height: 22px;
    }

    .pricing-free-content svg.not-included {
        fill: var(--gray);
        width: 22px;
        height: 22px;
    }


    .pricing-free-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

    }

    .pricing-button:enabled {
        width: 100px;
        height: 40px;
        border-radius: 20px;
        align-self: center;
    }

    .pricing-button:disabled {
        width: 100px;
        height: 40px;
        border-radius: 20px;
        align-self: center;
        cursor: not-allowed;
    }

    .amount {
        font-size: 1.5rem;

    }

    .info {
        /* border: 2px solid; */
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .info p {
        font-weight: 600 !important;
        font-size: 20px;
        color: #020342;
    }

    .pricing-premium .info p {
        font-weight: 600 !important;
        font-size: 20px;
        color: #38a1f7;
    }

    .pricing-free-heading {
        height: 50px;
        width: 95%;
        font-size: 2rem;
        font-weight: 600;
        background-color: #07033d;
        color: white;
        display: flex;
        justify-content: center;
        align-self: center;
        text-align: center;
    }

    .pricing-premium-heading {
        height: 50px;
        width: 95%;
        font-size: 2rem;
        font-weight: 600;
        background-color: #38a1f7;
        color: white;
        display: flex;
        justify-content: center;
        align-self: center;
        text-align: center;
    }


    .svg-wrapper {
        margin-left: 20px;
        width: 30px !important;
        height: 30px !important;
    }

    .pricing-container {
        /* padding: 20px; */
        /* height: 600px; */
        display: flex;
        flex-direction: column;
        /* overflow: auto; */
    }

    .pricing-selection {
        position: -webkit-sticky;
        width: 100%;
        min-height: 80px;
        border: 1px solid rgb(190, 190, 190);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ececec;
        position: sticky;
        top: 0;
        /* background-color: #fff; */
        /* Optional: Background color */
        z-index: 1;
    }

    .pricing-selection h3 {
        font-weight: 700;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }


    .pricing-free {
        /* min-height: 700px; */
        height: auto;
        border: 1px solid rgb(190, 190, 190);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        order: 6;
    }

    .pricing-premium {
        min-height: 700px;
        border: 1px solid rgb(190, 190, 190);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    /* premium checkout */

    .premium-checkout-overall {
        flex-direction: column;
        height: auto;
        overflow-y: auto;
    }

    .premium-checkout-left {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

    }

    .pc-right-1 {
        flex-direction: column;
        height: 15%;
        justify-content: space-around;
    }

    .pc-right-2 {
        height: 17%;
        justify-content: space-evenly;
        padding-left: 30px;
    }


    .pc-right-3 {
        height: 15%;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .pc-right-4 {
        width: 100%;
        margin-top: 20px;
    }

    .pc-right-ph {
        height: 10% !important;
        display: flex;
        justify-content: center;
    }

    .css-dcn0fb {
        width: 100% !important;
        /* max-width: 100%; */
    }


    .premium-checkout-right {
        width: 100%;
    }

    .css-1x97c6v {
        width: 100%;
    }

    .pc-right-1 .stsearch-box input {
        width: 270px !important;
    }

    .pc-right-3 .stsearch-box input {
        width: 270px !important;
    }



}

@media only screen and (min-width: 576px) and (max-width: 768px) {

    .pricing-free.order-1 {
        order: 1;
        /* Order when URL contains "features" */
    }

    .pricing-premium.order-1 {
        order: 6 !important;
    }

    .pc-left-1 {
        height: 60%;
    }

    .pricing-overall {
        padding: 2px !important;
    }


    .pricing-free-content li {
        /* display: flex; */
        align-items: center;
        font-size: 1em;
        font-weight: 500;
        line-height: 40px;
    }

    .pricing-free-content span {
        margin-left: 10px;
    }


    .pricing-free-content svg.starter {
        fill: var(--starter);
        width: 22px;
        height: 22px;
    }

    .pricing-free-content svg.essential {
        fill: var(--essential);
        width: 22px;
        height: 22px;
    }

    .pricing-free-content svg.not-included {
        fill: var(--gray);
        width: 22px;
        height: 22px;
    }


    .pricing-free-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

    }

    .premium-bg-none {
        padding-right: 10px;
        text-align: start;
    }

    .pricing-button:enabled {
        width: 100px;
        height: 40px;
        border-radius: 20px;
        align-self: center;
    }

    .pricing-button:disabled {
        width: 100px;
        height: 40px;
        border-radius: 20px;
        align-self: center;
        cursor: not-allowed;
    }

    .amount {
        font-size: 1.5rem;

    }

    .info {
        /* border: 2px solid; */
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .info p {
        font-weight: 600 !important;
        font-size: 20px;
        color: #020342;
    }

    .pricing-premium .info p {
        font-weight: 600 !important;
        font-size: 20px;
        color: #38a1f7;
    }

    .pricing-free-heading {
        height: 50px;
        width: 95%;
        font-size: 2rem;
        font-weight: 600;
        background-color: #07033d;

        color: white;
        display: flex;
        justify-content: center;
        align-self: center;
        text-align: center;
    }

    .pricing-premium-heading {
        height: 50px;
        width: 95%;
        font-size: 2rem;
        font-weight: 600;
        background-color: #38a1f7;
        color: white;
        display: flex;
        justify-content: center;
        align-self: center;
        text-align: center;
    }


    .svg-wrapper {
        margin-left: 20px;
        width: 30px !important;
        height: 30px !important;
    }

    .pricing-container {
        /* padding: 20px; */
        /* height: 600px; */
        display: flex;
        flex-direction: column;
        /* overflow: auto; */
    }

    .pricing-selection {
        position: -webkit-sticky;
        width: 100%;
        min-height: 80px;
        border: 1px solid rgb(190, 190, 190);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ececec;
        position: sticky;
        top: 0;
        /* background-color: #fff; */
        /* Optional: Background color */
        z-index: 1;
    }

    .pricing-selection h3 {
        font-weight: 700;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }


    .pricing-free {
        min-height: 650px;
        border: 1px solid rgb(190, 190, 190);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;


    }

    .pricing-premium {
        min-height: 650px;
        border: 1px solid rgb(190, 190, 190);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    /* premium checkout */

    .premium-checkout-overall {
        flex-direction: column;
        height: 260%;
        overflow-y: auto;
    }

    .premium-checkout-left {
        width: 100%;
        height: 40%;
    }

    .pc-right-1 {
        flex-direction: column;
        height: 30%;
        justify-content: space-around;
        align-items: center;
    }

    .pc-right-2 {
        height: 17%;
        justify-content: space-evenly;
    }

    .pc-right-3 {
        flex-direction: column;
        height: 35%;
        justify-content: space-around;
        align-items: center;
    }

    .pc-right-4 {
        width: 100%;
        margin-top: 45px;
    }

    .pc-right-ph {
        height: 17% !important;
        display: flex;
        justify-content: center;
    }

    .css-dcn0fb {
        width: 100% !important;
        /* max-width: 100%; */
    }


    .premium-checkout-right {
        width: 100%;
    }

    .css-1x97c6v {
        width: 100%;
    }

    .pc-right-1 .stsearch-box input {
        width: 270px !important;
    }

    .pc-right-3 .stsearch-box input {
        width: 270px !important;
    }



}


@media only screen and (min-width: 768px) and (max-width: 992px) {
    .pc-left-1 {
        height: 60%;
    }

    .premium-checkout-overall {
        flex-direction: column;
        height: 260%;
        overflow-y: auto;
    }

    .pc-left-2 {
        display: none;
    }

    .premium-checkout-left {
        width: 100%;
        height: 15%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .premium-checkout-right {
        border: 1px solid #ebebeb;
        width: 100%;
        overflow: auto;
    }

}