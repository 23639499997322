#root {
    margin: 0px;
    padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.sdinam-tv-overall {
    height: 100vh;
    width: 100vw;
}

.tv-container {
    padding: 60px;
}

.tv-button:enabled {
    /* background-color: #03045e !important;
    color: white !important; */
}


@media only screen and (min-width: 0px) and (max-width: 768px) {
    .tv-container {
        padding: 20px;
    }

}