/* EmailForm.css */
.admin-email-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .admin-input-field {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .admin-dark-mode {
    /* Add your dark mode styles here */
    background-color: #333;
    color: #fff;
  }
  
  .admin-submit-container {
    text-align: center;
  }
  
  .admin-custom-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-custom-button:hover {
    background-color: #0056b3;
  }
  